import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';

import Loading from './Loading.js';

import React, { useState, Suspense, lazy } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/system/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

import {BrowserView, MobileView} from 'react-device-detect';
import { Link } from 'react-scroll';

import './App.css';
import ContactFormButton from './components/ContactFormButton.js';

const About = lazy(() => import('./components/About.js'));
const ContactForm = lazy(() => import('./components/ContactForm.js'));
const TutorsList = lazy(() => import('./components/TutorsList.js'));
const Footer = lazy(() => import('./components/Footer.js'));
const ReviewCarousel = lazy(() => import('./components/ReviewCarousel.js'));
const CallButton = lazy(() => import('./components/CallButton.js'));

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = ['About', 'Tutors', 'Testimonials', 'Contact'];

function Header(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const sections = {
    About: 'about-section',
    Tutors: 'tutors-section',
    Testimonials: 'testimonials-section',
    Contact: 'contact-section',
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', color: '#214cce' }}>
      <Box sx={{my: 2}}>
        <img src='/imgs/logo.png' alt="Logo" style={{ width: '60%', height: 'auto' }} />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <Link
          key={item}
          to={sections[item]}
          spy={true}
          smooth={true}
          duration={500}
          offset={-70} // Adjust based on your header height
          onClick={handleDrawerToggle}
          >
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{maxWidth: 'none'}}>
      <CssBaseline />
      <AppBar component="nav" sx={{backgroundColor: 'white', color: '#214cce', position: 'fixed'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, my: 1, display: { xs: 'none', sm: 'block' }}}>
            <img src='/imgs/logo.png' alt="Logo" style={{ width: '120px', height: 'auto' }} />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Link
              key={item}
              to={sections[item]}
              spy={true}
              smooth={true}
              duration={500}
              offset={-70} // Adjust based on your header height
              >
              <Button key={item} sx={{ fontSize: '1.2rem' }}>
                {item}
              </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{paddingTop: {xs: '50px', sm: '88px', width: '100vw',}}}>
      <img src="/imgs/banner.jpg" alt="Banner" style={{ width: '100%', display: 'block'}} loading='lazy' />
      </Box>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

function App() {
  return (
    <Suspense fallback={<Loading/>}>
      <Grid container>
        <CssBaseline />
        <Header />
        <Box id="about-section" sx={{px: { xs: 5, md: 10, lg: 20, xl: 50 }, pt: 4, pb: 4}}>
          <About />
        </Box>
        <Box id="tutors-section" sx={{px: { xs: 5, md: 10, lg: 20, xl: 50 }, backgroundColor: '#EEF4F8', pt: 4, pb: 4}}>
          <TutorsList />
        </Box>
        <Box id="testimonials-section" sx={{px: { xs: 5, md: 10, lg: 20, xl: 50}, pt: 4, pb: 4, width: '100%'}}>
          <ReviewCarousel />
        </Box>
        <Box id="contact-section" sx={{width: '100%'}}>
          <ContactForm  />
          <Box sx={{px: { xs: 5, md: 10, lg: 20, xl: 50 }}}>
            <Footer />
          </Box>
        </Box>
      </Grid>
      <MobileView>
        <CallButton />
      </MobileView>
      <BrowserView>
      <Link
          to={'contact-section'}
          spy={true}
          smooth={true}
          duration={500}
          offset={-70} // Adjust based on your header height
        >
        <ContactFormButton />
        </Link>
      </BrowserView>
    </Suspense>
  );
}

export default App;
