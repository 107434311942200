import * as React from 'react';
import { Fab } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

function FabButton() {
  return (
    <>
      <Fab
        variant="extended"
        color="primary"
        href="contact-section"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          width: 125,
          height: 125,
          borderRadius: '50%',
          textAlign: 'center',
          backgroundColor: '#214cce',
          color: 'white',
          '&:hover': {
            backgroundColor: '#1b3a99',
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <EmailIcon fontSize="large" />
        <span style={{ fontSize: '13px', fontWeight: 'bold', textTransform: 'uppercase' }}>
          Get Your Free Consult!
        </span>
      </Fab>
    </>
  );
}

export default FabButton;
